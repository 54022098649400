<template>
    <div
        class="form-check form-check-sm form-check-custom form-check-solid">
        <input class="form-check-input" type="checkbox"/>
    </div>
</template>

<script>

import {defineComponent} from "vue";

export default defineComponent({
    name: "checkbox-table-header-comp",

});
</script>
